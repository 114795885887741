import React, { useState } from "react";
import Axios from "axios";
import ContactCard from "./Grid/ContactCard";

import OnCall from "./Dialpad/OnCall";

const Contacts = (props) => {
  const contactlist = props.personalcontacts;
  const [callStatus, setCallStatus] = useState({});
  const [dialContactName, setDialContactName] = useState("");
  const [dialContactNumber, setDialContactNumber] = useState("");
  const [Message, setMessage] = useState("");

  const [showElement, setShowElement] = React.useState(true);

  const [apiData, setapiData] = useState({
    apiurl: process.env.REACT_APP_BACKEND_API_URL,
    apitoken: process.env.REACT_APP_BACKEND_API_TOKEN,
  });

  const handleDialClick = async (contactname, phone) => {
    setDialContactName(contactname);
    setDialContactNumber(phone);
    const params = JSON.stringify({
      action: "dialeraction",
      method: "dial",
      emailaddress: props.accountemail,
      contact: phone,
    });
    const headerconfig = {
      headers: {
        "Content-Type": "application/json",
        authorization: apiData.apitoken!,
      },
    };
    const result = await Axios.post(apiData.apiurl!, params, headerconfig);
    const mesg = JSON.parse(result.data.message);
    if (mesg.status == "Success") {
      //setCallStatus("Dial Now");
    } else {
      setShowElement(true);
      setMessage(mesg.message);
      setTimeout(function () {
        setShowElement(false);
      }, 5000);
    }
  };
  const handleHungUpClick = () => {
    setCallStatus("Hung Up");
  };

  const fetchemail = (jsonobject) => {
    return ""; //jsonobject[0].address;
  };
  console.log(props);
  console.log("contactlist");
  return (
    <>
      <div className="recent-contacts">
        <div className="container">
          {showElement && Message ? (
            <div className="alert alert-warning">{Message}</div>
          ) : (
            <></>
          )}
          <div className="contact_list">
            <div className="row pt-2">
              {contactlist.map((contact) => (
                <>
                  {contact.mobilePhone ||
                  contact.businessPhones.length > 0 ||
                  contact.homePhones.length > 0 ? (
                    <>
                      {contact.mobilePhone ? (
                        <>
                          <div className="col-12" key={contact.id}>
                            <ContactCard
                              contactEmail={fetchemail(contact.emailAddresses)}
                              teamsUserCredential={props.teamsUserCredential}
                              contactName={contact.displayName}
                              contactNumber={contact.mobilePhone}
                              accountemail={props.accountemail}
                              onhandleDialClick={handleDialClick}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {contact.businessPhones.length > 0 ? (
                        <>
                          {contact.businessPhones.map((bphone) => (
                            <>
                              {bphone.trim().length > 0 ? (
                                <>
                                  <div className="col" key={bphone.id}>
                                    <ContactCard
                                      contactEmail={fetchemail(
                                        contact.emailAddresses
                                      )}
                                      contactName={contact.displayName}
                                      contactNumber={bphone}
                                      accountemail={props.accountemail}
                                      onhandleDialClick={handleDialClick}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {contact.homePhones.length > 0 ? (
                        <>
                          {contact.homePhones.map((hphone) => (
                            <>
                              {hphone.trim().length > 0 ? (
                                <>
                                  <div className="col" key={hphone.id}>
                                    <ContactCard
                                      contactemail={fetchemail(
                                        contact.emailAddresses
                                      )}
                                      teamsUserCredential={
                                        props.teamsUserCredential
                                      }
                                      contactName={contact.displayName}
                                      contactNumber={hphone}
                                      accountemail={props.accountemail}
                                      onhandleDialClick={handleDialClick}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <>
        {callStatus == "Dial Now" && (
          <OnCall
            name={dialContactName}
            number={dialContactNumber}
            status={callStatus}
            onhandleHungUpClick={handleHungUpClick}
            accountemail={props.accountemail}
          />
        )}
      </>
    </>
  );
};
export default Contacts;
