import { useEffect } from "react";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "./lib/config";
import Main from "./Main";
import { TeamsFxContext } from "./Context";
import { app } from "@microsoft/teams-js";

const App = () => {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        // Hide the loading indicator.
        app.notifySuccess();
      });
  }, [loading]);

  return (
    <TeamsFxContext.Provider value={{ teamsUserCredential }}>
      {!loading && <Main />}
    </TeamsFxContext.Provider>
  );
};

export default App;
