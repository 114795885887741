import { useContext, useState, useEffect } from "react";
import Configure from "./Configure";
import Axios from "axios";
import { TeamsFxContext } from "./Context";
import { useData } from "@microsoft/teamsfx-react";
import InfinityOne from "./InfinityOne";
import SideBar from "./SideBar";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const BACKEND_API_TOKEN = process.env.REACT_APP_BACKEND_API_TOKEN;

const validateaccount = async (
  emailaddress,
  backendapiurl,
  backendapitoken
) => {
  const params = JSON.stringify({
    action: "validate",
    emailaddress: emailaddress,
  });
  const headerconfig = {
    headers: {
      "Content-Type": "application/json",
      authorization: backendapitoken,
    },
  };

  const response = await Axios.post(backendapiurl, params, headerconfig);
  return response;
};

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dialersubscribed, setDialersubscribed] = useState("");
  const [frameUrl, setFrameUrl] = useState("");
  const [profileMail, setProfileMail] = useState("");
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleLogin = async () => {
    /* try {
      await teamsUserCredential!.login(["User.Read"]);
      const userInfo = await teamsUserCredential!.getUserInfo();

      setProfileMail(userInfo.preferredUserName);
      const account = {
        homeAccountId: userInfo.objectId,
        environment: "login.microsoftonline.com",
        tenantId: userInfo.tenantId,
        username: userInfo.preferredUserName,
        localAccountId: userInfo.objectId,
        name: userInfo.displayName,
      };

      setIsLoading(false);

      fetchSubscriptionStatus(account.username);
    } catch (err) {
      alert("Login failed: " + err);
      return;
    }*/
  };
  useEffect(() => {
    /*if (isAuthenticated == true) {
      console.log(accounts[0].username);
      fetchSubscriptionStatus(accounts[0].username);
    }*/

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const className =
    windowWidth <= 768
      ? "col-12 pe-0 collapse"
      : "col-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 pe-0";

  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      fetchSubscriptionStatus(userInfo.preferredUserName);
      setProfileMail(userInfo.preferredUserName);
      return userInfo;
    }
  });
  const userName = loading || error ? "" : data!.displayName;

  const fetchSubscriptionStatus = (email) => {
    if (email !== undefined) {
      if (email != "Null") {
        const subscribed = validateaccount(
          email,
          BACKEND_API_URL,
          BACKEND_API_TOKEN
        );
        subscribed.then(function (result) {
          const res = JSON.parse(result.data.message);
          if (res.status == "Success") {
            setDialersubscribed("Valid");
            setFrameUrl(res.frameurl);
          } else {
            setDialersubscribed("InValid");
          }
          setIsLoading(false);
        });
      }
    }
  };

  const [timeOut, setTimeOut] = useState(false);

  const HandleSubscription = (status) => {
    setDialersubscribed(status);
    if (timeOut == false) {
      setTimeOut(true);
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setTimeOut(false);
      }, 3000);
    }
  };

  return (
    <>
      {loading && (
        <div className="contact_bg loader">
          <div className="spinner"></div>
        </div>
      )}
      {!loading && !data && (
        <div className="content_bg">
          <div className="w-100 h-100 text-center pt-5 pb-5">
            <button className="mt-20 btn submit-btn reload">
              SignIn With Microsoft
            </button>
          </div>
        </div>
      )}
      {!loading && data && (
        <>
          {isLoading ? (
            <div className="contact_bg loader">
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="contact_bg">
              {dialersubscribed === "Valid" ? (
                <>
                  <div className="row">
                    {windowWidth <= 768 && (
                      <div className="col-12">
                        <button
                          className="btn btn-success btn-viewrecent w-100 rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseContainer"
                          aria-expanded="false"
                          aria-controls="collapseContainer"
                        >
                          View Contacts
                        </button>
                      </div>
                    )}
                    <div className={className} id="collapseContainer">
                      <SideBar accountemail={profileMail} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-9 col-xl-10 col-xxl-10 ps-0">
                      <InfinityOne frameUrl={frameUrl} />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {dialersubscribed === "InValid" ? (
                <>
                  <Configure
                    onSubscription={HandleSubscription}
                    userEmail={profileMail}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Main;
