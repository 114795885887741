import React from "react";

const InfinityOne = (props) => {
  return (
    <iframe
      src={props.frameUrl}
      title="InfinityOne"
      allow="camera;microphone"
    ></iframe>
  );
};

export default InfinityOne;
